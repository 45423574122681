import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/germany.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);


const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  Shopping in Australia{" "}
                  <br className="d-none d-lg-block" /> And forwarding to Germany
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Feb 16, 2020
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img src={titleImage} alt="Shopping From Germany" className={styles.titleImage} />
                  </div>
                  <div>
                <p>Enjoy Australian products in Germany with no hold backs</p>
                <p>It’s a common occurrence to find many Australian stores don’t ship internationally or to Germany, so we’re here to fix that for you. With a few extremely simple steps you can have any product from Australia lying on your doorstep in pristine condition at a minimal cost, so no more dishing all your wallet’s contents out to high-cost shipping companies.</p>
                <p>Spend your hard earned money on whatever Australian products you’d like from any store at all, and we’ll redirect it to your chosen address in Germany, making sure the highest quality and safety standards are always maintained. With our simple and dedicated parcel forwarding services, you can leave all the hard-work completely in our hands</p>
                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>Germany</BadgePost>
                  <BadgePost>Shopping</BadgePost>
                  <BadgePost>Mail forwarding</BadgePost>

                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
